import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const SocialPage = () => (
  <Layout>
    <SEO title="E1 Transit on Social Media" />
    <main>
      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="why-use-e1"
      >
        <section
          id="intro"
          style={{ height: '600px', backgroundImage: `url(${HeroImage})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>E1 Transit on Social Media</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 main-text">
          <p>
            {' '}
            Like our Facebook page for updates here. This is our main community:{' '}
            <a
              href="https://www.facebook.com/e1transit"
              target="_blank"
              rel="noreferrer"
            >
              facebook.com/e1transit
            </a>
          </p>
          <p>
            Follow us on Twitter here:{' '}
            <a
              href="https://www.twitter.com/e1transit"
              target="_blank"
              rel="noreferrer"
            >
              @e1transit
            </a>
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default SocialPage;
